import Layout from '../components/Layout';
import ImageWrapper from '../components/ImageWrapper';
import React from 'react';
import { graphql, navigate } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Button } from '@material-ui/core';
import ReactCompareImage from 'react-compare-image';
import { marked } from 'marked';
import './me.scss';

interface Props {
  data: any;
}

const BLOCK_CLASSNAME = 'me';

const Me: React.FC<Props> = ({ data }: Props) => {
  const { description, description2, profilePicture, comparisonImages } = data.contentfulAboutMe;
  const image = getImage(profilePicture);

  const perChunk = 2;
  const compareImageArray = comparisonImages.reduce(
    (all: any[], one: ConcatArray<never>, i: number) => {
      const ch = Math.floor(i / perChunk);
      all[ch] = [].concat(all[ch] || [], one);
      return all;
    },
    [],
  );

  if (!image) {
    return <></>;
  }

  const firstTwo = compareImageArray.splice(0, 2);

  const renderCompareImage = (array: any, index: number): JSX.Element => (
    <ReactCompareImage
      key={index}
      leftImage={array[0]?.max?.images?.fallback?.src}
      rightImage={array[1]?.max?.images?.fallback?.src}
      leftImageAlt={array[0]?.category}
      rightImageAlt={array[1]?.category}
    />
  );

  return (
    <Layout>
      <div className={BLOCK_CLASSNAME}>
        <div className={`${BLOCK_CLASSNAME}__container`}>
          <div className={`${BLOCK_CLASSNAME}__content`}>
            <div>
              <h1>Über mich</h1>
              <div dangerouslySetInnerHTML={{ __html: marked.parse(description.description) }} />
              <br />
              <Button
                onClick={(): Promise<void> => navigate('/contact')}
                variant="contained"
                size="large"
              >
                Kontaktiere mich
              </Button>
            </div>
          </div>
          <ImageWrapper noClick>
            <GatsbyImage image={image} loading="lazy" alt={profilePicture.title} />
          </ImageWrapper>
        </div>
        <div dangerouslySetInnerHTML={{ __html: marked.parse(description2.description2) }} />
        {firstTwo.map((i: any, index: number) => (
          <div key={index} className={`${BLOCK_CLASSNAME}__compare-container-full`}>
            {renderCompareImage(i, index)}
          </div>
        ))}
        <div className={`${BLOCK_CLASSNAME}__compare-container`}>
          {compareImageArray.map((i: any, index: number) => renderCompareImage(i, index))}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query MePage {
    contentfulAboutMe {
      description {
        description
      }
      name
      profilePicture {
        gatsbyImageData
        title
      }
      description2 {
        description2
      }
      comparisonImages {
        max: gatsbyImageData(quality: 100, width: 1440)
      }
    }
  }
`;

export default Me;
